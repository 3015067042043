<template>
  <div class="list-wrap">
    <div class="search">
      <el-form :inline="true" class="class-search">
        <el-form-item class="search-name">
          <el-input
            size="small"
            placeholder="按订单编号"
            suffix-icon="search"
            v-model="params.code"
            @input='inputChange'
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="class-tab">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="number" label="序号">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column prop="code" label="订单编号"> </el-table-column>
        <el-table-column prop="type" label="收益类型"> </el-table-column>
        <el-table-column prop="payMoney" label="交易金额"> </el-table-column>
        <el-table-column prop="rate" label="费率"> </el-table-column>
        <el-table-column prop="amount" label="收益金额"> </el-table-column>
        <el-table-column prop="createTime" label="时间">
          <template slot-scope="scope">
            {{scope.row.createTime*1000 | formatDate}}
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.currentPage"
        :page-sizes="[2, 5, 10, 15]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next"
        :total="total"
        v-if="tableData.length"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { formatDate } from "@/utils/date.js";
export default {
  name: "profitList",
  data() {
    return {
      tableData: [],
      page: {
        total: 0, // 总页数
        current: 1, // 当前页数
        size: 10, // 每页显示多少条
      },
      params: {
        token: "",
        userId: "",
        code: "",
      },
      total: 0,
      rate: 0,
    };
  },
  created() {
    this.params.token = window.localStorage.getItem("token");
    this.params.userId = window.localStorage.getItem("userId");
    this.getData();
  },
  filters: {
    formatDate(time) {
      var date = new Date(time);
      return formatDate(date, "yyyy-MM-dd hh:mm");
    },
  },
  methods: {
    getData(page) {
      this.$axios({
        method: "post",
        params: Object.assign(
          {
            current: page == null ? this.page.current : page.currentPage,
            size: page == null ? this.page.size : page.pageSize,
          },
          this.params
        ),
        url: `/teacher/bill/list`,
      }).then((res) => {
        if (res.data.code !== "0000") {
          return this.$message.error(res.data.message);
        }
        this.tableData = res.data.data.bills.records;
        this.rate = res.data.data.rate == null ? 0 : res.data.data.rate;
        this.total = res.data.data.bills.total;
        this.tableData.forEach((item) => {
          let key = "rate";
          let value = this.rate;
          item[key] = value;
        });
      });
    },
    //分页
    // 监听 pagesize改变的事件
    handleSizeChange(newSize) {
      // console.log(newSize)
      this.page.size = newSize;
      this.getData();
    },
    // 监听 页码值 改变事件
    handleCurrentChange(newSize) {
      this.page.current = newSize;
      this.getData();
    },
    //搜索功能
    inputChange(){
      this.getData();
    }
  },
};
</script>
